import { storeToRefs } from 'pinia'
import {
  useUserStore,
  useCompaniesStore,
  useUserPermissionStore
} from '@/common/stores'

export const redirectNonMaintainers = async (to, _from, next) => {
  const companiesStore = useCompaniesStore()
  const userPermissionStore = useUserPermissionStore()
  const { userPermissions } = storeToRefs(userPermissionStore)

  const userStore = useUserStore()
  await userStore.decodeUserJwt()

  if (userStore.isAdminUser) {
    next()
    return
  }

  const currentCompanyId = to.params.companyId
  const requests = [companiesStore.fetchCompanyList(userStore.userId)]
  if (!userPermissions.value.length) {
    requests.push(userPermissionStore.fetchPermissions())
  }
  await Promise.allSettled(requests)
  const isCourseManager = userPermissionStore.isCourseManager(currentCompanyId)

  if (!isCourseManager && !userStore.isAdminUser) {
    next({ name: 'Dashboard', params: to.params })
    return
  }

  next()
}
